import useMemberOverview from "@core/services/nocd-api/clinician-dashboard/queries/useMemberOverview";
import { useBookingStatus } from "@core/services/nocd-api/members";
import { MemberChartPageTabs } from "@core/types";
import Loading from "@core/ui/Loading";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Close, ExpandMore, Warning } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Link as MuiLink,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { FC, ReactNode } from "react";

const BasicStat: FC<{
  title: string;
  value?: ReactNode;
  alignCenter?: boolean;
}> = ({ title, value, alignCenter = true }) => {
  return (
    <Box sx={{ textAlign: alignCenter ? "center" : "left", padding: "4px" }}>
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: "12px",
          color: "#AAAEB4",
          textTransform: "uppercase",
        }}
      >
        {title}
      </Typography>

      <Typography>{value ?? "-"}</Typography>
    </Box>
  );
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  userId: number;
  hideMessagingLink: boolean;
}

const MemberSpotlight: FC<Props> = ({
  isOpen,
  onClose,
  userId,
  hideMessagingLink,
}) => {
  const { data: memberData, isLoading, error } = useMemberOverview(userId);

  const { data: bookingStatus, isLoading: isLoadingBookingStatus } =
    useBookingStatus(userId);

  if (isLoading || isLoadingBookingStatus) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress disableShrink size="15px" sx={{ ml: 1 }} />
      </Box>
    );
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      scroll="paper"
      onClose={onClose}
      PaperProps={{
        sx: {
          position: { md: "absolute" },
          right: { md: "24px" },
          borderRadius: "16px",
        },
      }}
    >
      <DialogContent>
        {
          // eslint-disable-next-line no-nested-ternary
          isLoading ? (
            <Box p={6}>
              <Loading />
            </Box>
          ) : error ? (
            <Box p={6}>
              <Typography color="error.main">{error.message}</Typography>
            </Box>
          ) : (
            <Stack
              direction="column"
              spacing={2}
              sx={{
                borderRadius: "8px",
                backgroundColor: "white",
              }}
            >
              {bookingStatus?.internal_warning ? (
                <Box mb={3}>
                  <Alert severity="error">
                    {bookingStatus.internal_warning}
                  </Alert>
                </Box>
              ) : null}

              <Stack direction="row" spacing={1}>
                <Avatar
                  alt={memberData.name}
                  src={memberData.avatar_url}
                  sx={{
                    width: "42px",
                    height: "42px",
                  }}
                />

                <Box flexGrow={1}>
                  <Typography sx={{ fontWeight: 700 }}>
                    {memberData.name}
                  </Typography>

                  <Typography variant="body2">
                    {memberData.pronouns ?? "-"}
                  </Typography>
                </Box>

                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Stack>

              <Grid container rowGap={2}>
                <Grid item xs={4}>
                  <BasicStat title="Member ID" value={memberData.user_id} />
                </Grid>

                <Grid item xs={4}>
                  <BasicStat
                    title="Joined"
                    value={
                      memberData.bootstrapped_at
                        ? format(
                            new Date(memberData.bootstrapped_at),
                            "MMM dd, yyyy"
                          )
                        : null
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <BasicStat
                    title="Scheduled Sessions"
                    value={memberData.session_count}
                  />
                </Grid>

                <Grid item xs={4}>
                  <BasicStat
                    title="Insurance"
                    value={memberData.insurance_provider}
                  />
                </Grid>

                <Grid item xs={4}>
                  <BasicStat
                    title="Location"
                    value={memberData.location ? memberData.location : null}
                  />
                </Grid>

                <Grid item xs={4} />
              </Grid>

              <Divider />

              <Stack divider={<Divider />}>
                <Accordion
                  disableGutters
                  defaultExpanded
                  sx={{
                    border: "none",
                  }}
                >
                  <AccordionSummary
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      flex: "1",
                      alignItems: "center",
                      flexDirection: "row-reverse",
                      padding: 0,
                      "& .MuiAccordionSummary-expandIconWrapper": {
                        transform: "rotate(-90deg)",
                      },
                      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                        transform: "rotate(0deg)",
                      },
                    }}
                    expandIcon={<ExpandMore />}
                  >
                    <Typography sx={{ fontWeight: 700 }}>
                      Assessments
                    </Typography>

                    <Box
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <MuiLink
                        // Prevent accordion from opening/closing when clicked
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        href={`/members/${userId}?tab=${encodeURIComponent(
                          MemberChartPageTabs.HISTORICAL_ASSESSMENT_RESULTS
                        )}`}
                        underline="none"
                        target="_blank"
                        color="nocdBlue.main"
                        sx={{
                          "&:hover": {
                            color: "nocdBlue.dark",
                          },
                          fontWeight: 700,
                        }}
                      >
                        See all
                      </MuiLink>
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails>
                    {Array.isArray(memberData.outcomes) &&
                    memberData.outcomes.length > 0 ? (
                      memberData.outcomes.map((assessment) => (
                        <Stack
                          direction="row"
                          key={`assessment-${assessment?.form}-${assessment?.metric}-${assessment?.title}`}
                          spacing={2}
                          sx={{
                            py: 1,
                            alignItems: "center",
                          }}
                        >
                          <Typography>
                            {assessment.title ?? assessment?.help_text?.title}
                          </Typography>

                          <Typography
                            fontWeight={700}
                            sx={{
                              color: "#545CEA",
                            }}
                          >
                            {Math.round(assessment.lifetime_percent_change)}%
                          </Typography>
                        </Stack>
                      ))
                    ) : (
                      <Typography>No assessments completed yet</Typography>
                    )}
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  disableGutters
                  defaultExpanded
                  sx={{
                    border: "none",
                  }}
                >
                  <AccordionSummary
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      flex: "1",
                      alignItems: "center",
                      flexDirection: "row-reverse",
                      padding: 0,
                      "& .MuiAccordionSummary-expandIconWrapper": {
                        transform: "rotate(-90deg)",
                      },
                      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                        transform: "rotate(0deg)",
                      },
                    }}
                    expandIcon={<ExpandMore />}
                  >
                    <Typography sx={{ fontWeight: 700 }}>
                      First Session Forms
                    </Typography>

                    <Box
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <MuiLink
                        // Prevent accordion from opening/closing when clicked
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        href={`/members/${userId}`}
                        underline="none"
                        target="_blank"
                        color="nocdBlue.main"
                        sx={{
                          "&:hover": {
                            color: "nocdBlue.dark",
                          },
                          fontWeight: 700,
                        }}
                      >
                        See all
                      </MuiLink>
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails>
                    {memberData.forms.map((form) => (
                      <Stack
                        direction="row"
                        key={`form-${form.description}`}
                        spacing={1}
                        sx={{
                          py: 1,
                          alignItems: "center",
                        }}
                      >
                        {form.is_completed ? (
                          <CheckCircleIcon
                            style={{
                              width: "16px",
                              height: "16px",
                              color: "#22C55E",
                            }}
                          />
                        ) : (
                          <Tooltip title="Not completed">
                            <Warning
                              sx={{
                                width: "16px",
                                height: "16px",
                                color: "nocdOrange.main",
                              }}
                            />
                          </Tooltip>
                        )}
                        <Typography>{form.description}</Typography>{" "}
                      </Stack>
                    ))}
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  defaultExpanded
                  sx={{
                    border: "none",
                  }}
                  disableGutters
                >
                  <AccordionSummary
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      flex: "1",
                      alignItems: "center",
                      flexDirection: "row-reverse",
                      padding: 0,
                      "& .MuiAccordionSummary-expandIconWrapper": {
                        transform: "rotate(-90deg)",
                      },
                      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                        transform: "rotate(0deg)",
                      },
                    }}
                    expandIcon={<ExpandMore />}
                  >
                    <Typography sx={{ fontWeight: 700 }}>Exercises</Typography>

                    <Box
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <MuiLink
                        // Prevent accordion from opening/closing when clicked
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        href={`/members/${userId}?tab=${encodeURIComponent(
                          MemberChartPageTabs.SOS_EXERCISES
                        )}`}
                        underline="none"
                        target="_blank"
                        color="nocdBlue.main"
                        sx={{
                          "&:hover": {
                            color: "nocdBlue.dark",
                          },
                          fontWeight: 700,
                        }}
                      >
                        See all
                      </MuiLink>
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails>
                    {Array.isArray(memberData.exercises) &&
                    memberData.exercises.length > 0 ? (
                      <Stack spacing={1}>
                        {memberData.exercises.map((exercise) => (
                          <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                            key={`exercise-${exercise.completed_at}`}
                          >
                            <Box>
                              <Typography noWrap>
                                {exercise.exposure}
                              </Typography>

                              <Stack spacing={2} direction="row">
                                <Box>
                                  <Typography
                                    fontWeight={700}
                                    sx={{
                                      textTransform: "uppercase",
                                      fontSize: "12px",
                                      color: "#AAAEB4",
                                    }}
                                  >
                                    Start
                                  </Typography>

                                  <Typography
                                    sx={{
                                      color: "#535CEA",
                                      fontWeight: 700,
                                    }}
                                  >
                                    {exercise.distress_before}
                                  </Typography>
                                </Box>

                                <Box>
                                  <Typography
                                    fontWeight={700}
                                    sx={{
                                      textTransform: "uppercase",
                                      fontSize: "12px",
                                      color: "#AAAEB4",
                                    }}
                                  >
                                    End
                                  </Typography>

                                  <Typography
                                    sx={{
                                      fontWeight: 700,
                                      color: "#535CEA",
                                    }}
                                  >
                                    {exercise.distress_after}
                                  </Typography>
                                </Box>
                              </Stack>
                            </Box>

                            <Box>
                              <Typography>
                                {format(
                                  new Date(exercise.completed_at),
                                  "MMM dd"
                                )}
                              </Typography>
                            </Box>
                          </Stack>
                        ))}
                      </Stack>
                    ) : (
                      <Typography>No exercises completed yet</Typography>
                    )}
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  defaultExpanded
                  sx={{
                    border: "none",
                  }}
                  disableGutters
                >
                  <AccordionSummary
                    sx={{
                      padding: 0,
                      flexDirection: "row-reverse",
                      "& .MuiAccordionSummary-expandIconWrapper": {
                        transform: "rotate(-90deg)",
                      },
                      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                        transform: "rotate(0deg)",
                      },
                    }}
                    expandIcon={<ExpandMore />}
                  >
                    <Typography sx={{ fontWeight: 700 }}>Background</Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={12}>
                        <BasicStat
                          title="Ethnicity"
                          value={memberData.background?.ethnicity}
                          alignCenter={false}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <BasicStat
                          title="Religion"
                          value={memberData.background?.religion}
                          alignCenter={false}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <BasicStat
                          title="Language"
                          value={memberData.background?.language}
                          alignCenter={false}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <BasicStat
                          title="Gender"
                          value={memberData.background?.gender}
                          alignCenter={false}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <BasicStat
                          title="Sexual Orientaion"
                          value={memberData.background?.sexual_orientation}
                          alignCenter={false}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <BasicStat
                          title="Years of education"
                          value={memberData.background?.years_of_education}
                          alignCenter={false}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <BasicStat
                          title="Student status"
                          value={memberData.background?.student_status}
                          alignCenter={false}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <BasicStat
                          title="Employment"
                          value={memberData.background?.employment_status}
                          alignCenter={false}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <BasicStat
                          title="Military"
                          value={memberData.background?.military_served}
                          alignCenter={false}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <BasicStat
                          title="Reason for treatment"
                          value={memberData.background?.reason_for_treatment}
                          alignCenter={false}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <BasicStat
                          title="Therapy Goal"
                          value={memberData.background?.therapy_goal}
                          alignCenter={false}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Stack>
            </Stack>
          )
        }
      </DialogContent>
      <DialogActions>
        <Stack
          direction="column"
          spacing={1}
          sx={{
            width: "100%",
            padding: "10px",
          }}
        >
          {hideMessagingLink ? null : (
            <Button
              component="a"
              href="/messaging"
              disableElevation
              target="_blank"
              variant="outlined"
              fullWidth
            >
              Messaging
            </Button>
          )}
          <Button
            component="a"
            href={`/members/${userId}`}
            variant="contained"
            disableElevation
            target="_blank"
            fullWidth
          >
            Open Chart
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default MemberSpotlight;
